<script>
import axios from "@/config/axios";
import Swal from "sweetalert2";
import { CountTo } from "vue3-count-to";

import { BASE_URL } from "@/helpers/constants";

export default {
  props: ['campaigns'],
  components: {
    CountTo,
  },
  data() {
    return {
      searchQuery: "",
      files: [],
      files_loading_ids: [],
    };
  },
  computed: {
    campaignsMapped() {
      return this.campaigns;
    },
    campaignsPending() {
      return this.campaignsMapped.filter((ev) => ev.status == 0);
    },
    campaignsSended() {
      return this.campaignsMapped.filter((ev) => ev.status == 1 || ev.status == 3);
    },
    campaignsFailed() {
      return this.campaignsMapped.filter((ev) => ev.status == 2);
    },

    displayedFiles() {
      return this.campaignsMapped;
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedFiles.filter((data) => {
          return (
            data.name?.toLowerCase().includes(search) ||
            data.dg?.toLowerCase().includes(search) ||
            data.phone_number?.toLowerCase().includes(search) ||
            data.email?.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedFiles;
      }
    },
  },
  methods: {
    handleGoToDetails(campaign) {
      this.$store.dispatch("campaigns/setCurrentCampaign", campaign);
      this.$router.push({
        name: "campagnes-sms-details",
        params: { campaignId: campaign.id },
      });
    },
    handleRefresh() {
      this.$store.dispatch("campaigns/findAll");
    },

    handleDownloadScanned() {
      axios({
        url: BASE_URL + "/document/download",
        method: "GET",
        responseType: "blob", // Indique que la réponse est un blob (fichier)
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "gicam-list-" + new Date().getTime() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(
            "Erreur lors du téléchargement du fichier Excel:",
            error
          );
        });
    },

    async handleResendEmail(file) {
      if (file.status === "success") {
        let resend = false;

        await Swal.fire({
          title: "Voulez-vous vraiment renvoyer le message ?",
          text: "Le document semble déjà avoir été envoyé",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui, renvoyer",
          cancelButtonText: "Non, annuler",
          confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
          cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            resend = true;
          }
        });

        if (!resend) {
          return;
        }
      }

      this.files_loading_ids.push(file.id);
      try {
        const { data } = await axios.post(BASE_URL + "/resend-email", {
          matricule: file.matricule,
        });
        console.log(data);
        this.$store.dispatch("campaigns/findAll");
        this.$saSuccess("Email envoyé", "Le document a été envoyé");
      } catch (error) {
        this.$store.dispatch("campaigns/findAll");
        this.$saError("Une erreur est survenue", "Veuillez réessayer");
        console.log(error);
      }
      this.files_loading_ids = this.files_loading_ids.filter(
        (id) => id !== file.id
      );
    },

    async handleResendSMS(file) {
      if (file.status === "success") {
        let resend = false;

        await Swal.fire({
          title: "Voulez-vous vraiment renvoyer le message ?",
          text: "Le document semble déjà avoir été envoyé",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui, renvoyer",
          cancelButtonText: "Non, annuler",
          confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
          cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            resend = true;
          }
        });

        if (!resend) {
          return;
        }
      }

      this.files_loading_ids.push(file.id);
      try {
        const { data } = await axios.post(BASE_URL + "/resend-sms", {
          matricule: file.matricule,
        });
        console.log(data);
        this.$store.dispatch("campaigns/findAll");
        this.$saSuccess("SMS envoyé", "Le document a été envoyé");
      } catch (error) {
        this.$store.dispatch("campaigns/findAll");
        this.$saError("Une erreur est survenue", "Veuillez réessayer");
        console.log(error);
      }
      this.files_loading_ids = this.files_loading_ids.filter(
        (id) => id !== file.id
      );
    },

    async handleConfirmUser(file) {
      if (file.status === "success") {
        let resend = false;

        await Swal.fire({
          html:
            "Voulez-vous vraiment confirmer l'arrivée de <b>" +
            file.name +
            "</b> ?",
          // text: "Le document semble déjà avoir été envoyé",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui, confirmer",
          cancelButtonText: "Non, annuler",
          confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
          cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            resend = true;
          }
        });

        if (!resend) {
          return;
        }
      }

      this.files_loading_ids.push(file.id);
      try {
        const { data } = await axios.patch(
          BASE_URL + "/" + file.matricule + "/confirm"
        );
        console.log(data);
        this.$store.dispatch("campaigns/findAll");
        this.$saSuccess("Confirmation", "Présence de l'entreprise confirmée");
      } catch (error) {
        this.$store.dispatch("campaigns/findAll");
        this.$saError("Une erreur est survenue", "Veuillez réessayer");
        console.log(error);
      }
      this.files_loading_ids = this.files_loading_ids.filter(
        (id) => id !== file.id
      );
    },
  },
};
</script>

<template>
  <div class="card mt-3 mb-5">
    <div class="card-header p-0 border-0 bg-soft-light">
      <div class="row g-0 text-center">
        <div class="col-6 col-sm-3">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1">
              <count-to
                :duration="2000"
                :startVal="0"
                :endVal="campaignsMapped.length"
              ></count-to>
            </h5>
            <p class="text-muted mb-0">Campagnes</p>
          </div>
        </div>
        <!--end col-->
        <div class="col-6 col-sm-3">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1">
              <count-to
                :duration="2000"
                :startVal="0"
                :endVal="campaignsPending.length"
              ></count-to>
            </h5>
            <p class="text-muted mb-0">En attente</p>
          </div>
        </div>
        <div class="col-6 col-sm-3">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1">
              <count-to
                :duration="2000"
                :startVal="0"
                :endVal="campaignsSended.length"
                :class="'text-primary'"
              ></count-to>
            </h5>
            <p class="text-muted mb-0">Efectués</p>
          </div>
        </div>
        <!--end col-->
        <div class="col-6 col-sm-3">
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1">
              <count-to
                :duration="5000"
                :startVal="0"
                :endVal="campaignsFailed.length"
                :class="'text-danger'"
              ></count-to>
            </h5>
            <p class="text-muted mb-0">Échecs</p>
          </div>
        </div>
        <!--end col-->
      </div>
    </div>
  </div>
  <div class="row g-4 mb-3">
    <div class="col-sm">
      <div class="d-flex justify-content-sm-end">
        <div class="search-box ms-2">
          <input
            type="text"
            class="form-control"
            placeholder="Rechercher..."
            v-model="searchQuery"
          />
          <i class="ri-search-line search-icon"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center mb-4">
        <h5 class="card-title flex-grow-1">
          Liste des campagnes

          <span
            class="badge bg-soft-primary text-primary ms-2"
            v-if="searchQuery"
          >
            {{ resultQuery.length }} résultat(s) pour "{{ searchQuery }}" sur
            {{ displayedFiles.length }}
          </span>
        </h5>

        <button class="btn btn-primary" @click.prevent="handleRefresh">
          Actualiser
        </button>

        <div v-show="false" class="ms-2 dropdown">
          <a
            href="javascript:void(0);"
            class="btn btn-secondary"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i class="ri-download-fill me-2 align-bottom text-muted"></i>
            Télécharger
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li>
              <a class="dropdown-item" @click.prevent="handleDownloadScanned">
                <i class="ri-download-fill me-2 align-bottom text-muted"></i>
                Liste des présents
              </a>
            </li>

            <li>
              <hr class="dropdown-divider" />
            </li>

            <!-- <li>
              <a
                class="dropdown-item"
                href="javascript:void(0);"
                @click.prevent="handleDownload"
              >
                Toute la liste
              </a>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="table-responsive table-card">
            <table
              class="table table-borderless align-middle"
              style="font-size: 13px"
            >
              <thead class="table-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" style="width: 250px">Intitulé</th>
                  <th scope="col" style="width: 180px">Date</th>
                  <th scope="col" class="text-center">Ciblés</th>
                  <th scope="col" class="text-center">Envoyés</th>
                  <th scope="col" class="text-center">Echec</th>
                  <th scope="col" class="text-center">Pourcentage</th>
                  <th scope="col" class="text-center">Status</th>
                  <th scope="col" style="width: 120px">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="doc of resultQuery || []" :key="doc.id">
                  <td>{{ doc.id }}</td>
                  <td>{{ doc.name }}</td>
                  <td>
                    {{ doc.diffuse_at?.split(".")[0].replace("T", " ") ?? "-" }}
                  </td>
                  <td class="text-center">{{ doc.targets.length }}</td>
                  <td class="text-center">
                    {{ doc.targets.filter((t) => t.status == 1).length }}
                  </td>
                  <td class="text-center">
                    {{ doc.targets.filter((t) => t.status == 2).length }}
                  </td>
                  <td class="text-center">
                    {{
                      parseInt(
                        (doc.targets.filter((t) => t.status == 1).length /
                          doc.targets.length) *
                          100
                      )
                    }}%
                  </td>
                  <td class="text-center">
                    <span
                      v-if="doc.status == 0"
                      class="badge bg-soft-warning text-warning"
                    >
                      En attente
                    </span>

                    <span
                      v-else-if="doc.status == 1 || doc.status == 3"
                      class="badge bg-soft-success text-success"
                    >
                      Effectué
                    </span>

                    <span
                      v-else-if="doc.status == 2"
                      class="badge bg-soft-danger text-danger"
                    >
                      Échec
                    </span>
                  </td>
                  <td>
                    <div class="dropdown">
                      <a
                        href="javascript:void(0);"
                        class="btn btn-soft-secondary btn-sm btn-icon"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i class="ri-more-fill"></i>
                      </a>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <a
                            class="dropdown-item"
                            @click.prevent="handleGoToDetails(doc)"
                          >
                            <i
                              class="ri-eye-fill me-2 align-bottom text-muted"
                            ></i>
                            Gérer la campagne
                          </a>
                        </li>

                        <template v-if="false">
                          <li>
                            <hr class="dropdown-divider" />
                          </li>

                          <li>
                            <a
                              class="dropdown-item"
                              href="javascript:void(0);"
                              @click.prevent="handleResendEmail(doc)"
                            >
                              <i
                                class="ri-refresh-line me-2 align-bottom text-muted"
                              ></i>
                              Renvoyer l'email
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="javascript:void(0);"
                              @click.prevent="handleResendSMS(doc)"
                              ><i
                                class="ri-refresh-line me-2 align-bottom text-muted"
                              ></i>
                              Renvoyer le SMS
                            </a>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div class="text-center mt-3">
                      <a href="javascript:void(0);" class="text-success"
                        ><i
                          class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                        ></i>
                        Load more
                      </a>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
